import { uniqBy } from 'lodash';
import React, { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useSnackbar } from '../../../shared/components/snackbar/snackbar-context';
import { usePersistedState } from '../../../shared/hooks/use-persisted-state';
import { useAccountNetwork } from '../../account/use-account-network';
import { StationClient } from '../../client/station-clients/station-client';
import { useNetwork } from '../../network/network-context';
import { useWallet } from '../../wallet/wallet-context';
import {
    getBaseStatus,
    getFilteredInitiatedTransfers,
    getTransferCoins, getTransferEibcFeeCoins, loadIbcTransfer,
    loadIbcTransfers,
    loadInitiatedTransfers,
} from './ibc-status-service';
import { IbcTransferDetails, IbcTransferStatus } from './ibc-status-types';

const PAGE_SIZE = 20;

export interface IbcStatusContextValue {
    transfers: IbcTransferDetails[];
    allInitiatedTransfers: IbcTransferDetails[];
    totalCount: number;
    loading: boolean;
    showAll: boolean;
    initiatedTransfersLoading: boolean;
    searchText: string;
    statusesFilter?: IbcTransferStatus[];
    initiatedTransferCreating: boolean;
    setSearchText: (value: string) => void;
    setStatusesFilter: (statuses?: IbcTransferStatus[]) => void;
    getFixedTransfer: (transfer: IbcTransferDetails) => IbcTransferDetails | undefined;
    getFixedFullTransfer: (transfer: IbcTransferDetails, client: StationClient) => Promise<IbcTransferDetails | undefined>;
    setInitiatedTransferCreating: (value: boolean) => void;
    listenToIbcTransfer: (transferId: string) => void;
    setShowAll: (value: boolean) => void;
    loadMore: () => void;
    refreshData: () => void;
}

export const IbcStatusContext = createContext<IbcStatusContextValue>({} as IbcStatusContextValue);

export const useIbcStatus = (): IbcStatusContextValue => useContext(IbcStatusContext);

export const IbcStatusContextProvider = ({ children }: { children: ReactNode }) => {
    const { hubNetwork, hubChannelNetworkMap } = useNetwork();
    const { showMessage, removeMessage, getMessage } = useSnackbar();
    const { hubWallet } = useWallet();
    const [ hubNetworkData ] = useAccountNetwork(hubNetwork, false);
    const [ loading, setLoading ] = useState(true);
    const [ indexedTransfers, setIndexedTransfers ] = useState<IbcTransferDetails[]>([]);
    const [ allInitiatedTransfers, setAllInitiatedTransfers ] = useState<IbcTransferDetails[]>([]);
    const [ initiatedTransfersLoading, setInitiatedTransfersLoading ] = useState(true);
    const [ indexedTotalCount, setIndexedTotalCount ] = useState(0);
    const [ toRefresh, setToRefresh ] = useState(false);
    const [ searchText, setSearchText ] = useState('');
    const [ statusesFilter, setStatusesFilter ] = useState<IbcTransferStatus[]>();
    const [ initiatedTransferCreating, setInitiatedTransferCreating ] = useState(false);
    const [ page, setPage ] = useState(0);
    const [ , setStatusesListener ] = useState<NodeJS.Timer>();
    const [ listenedTransferIds, setListenedTransferIds ] = useState<string[]>([]);
    const [ canLoadMore, setCanLoadMore ] = useState(true);
    const [ showAll, setShowAll ] = usePersistedState('show-all-ibc-transfers', false);

    useEffect(() => {
        if (!hubWallet) {
            setIndexedTransfers([]);
            setAllInitiatedTransfers([]);
            setLoading(false);
            setInitiatedTransfersLoading(false);
            setPage(0);
            setCanLoadMore(false);
        }
    }, [ hubWallet ]);

    useEffect(() => {
        if (hubWallet) {
            setLoading(true);
            setPage(0);
            setIndexedTransfers([]);
            setCanLoadMore(true);
        }
    }, [ hubWallet, searchText, statusesFilter, showAll ]);

    useEffect(() => {
        if (hubWallet) {
            setInitiatedTransfersLoading(true);
        }
    }, [ hubWallet ]);

    useEffect(() => {
        if (hubWallet && (toRefresh || !initiatedTransferCreating)) {
            setIndexedTransfers([]);
            setAllInitiatedTransfers([]);
            setInitiatedTransfersLoading(true);
            setLoading(true);
            setToRefresh(false);
            setCanLoadMore(true);
        }
    }, [ hubWallet, initiatedTransferCreating, toRefresh ]);

    const getFixedTransfer = useCallback((transfer: IbcTransferDetails): IbcTransferDetails | undefined => {
        if (!hubNetwork) {
            return undefined;
        }
        const sourceNetwork = transfer.type === 'In' ? hubChannelNetworkMap[transfer.destinationChannel] : hubNetwork;
        const destinationNetwork = transfer.type === 'In' ? hubNetwork : hubChannelNetworkMap[transfer.sourceChannel];
        const coins = getTransferCoins(sourceNetwork, hubNetwork, transfer.denom, transfer.amount, hubChannelNetworkMap);
        const baseStatus = getBaseStatus(transfer.status);
        return { ...transfer, sourceNetwork, destinationNetwork, baseStatus, coins };
    }, [ hubChannelNetworkMap, hubNetwork ]);

    useEffect(() => {
        setStatusesListener((listener) => {
            if (listener) {
                clearInterval(listener);
            }
            return setInterval(async () => {
                const updatedListenedTransferIds = await Promise.all(listenedTransferIds.map(async (transferId) => {
                    const ibcTransfer = await loadIbcTransfer(transferId);
                    if (!ibcTransfer) {
                        return transferId;
                    }
                    const fixedTransfer = getFixedTransfer(ibcTransfer);
                    if (fixedTransfer) {
                        const transferIndex = indexedTransfers.findIndex((transfer) => transfer.id === transferId);
                        if (transferIndex < 0) {
                            setAllInitiatedTransfers((transfers) => transfers.filter((transfer) => transfer.id !== transferId));
                            setIndexedTransfers((transfers) => [ fixedTransfer, ...transfers ]);
                        } else {
                            setIndexedTransfers((transfers) => {
                                transfers[transferIndex] = fixedTransfer;
                                return [ ...transfers ];
                            });
                        }
                    }
                    const baseStatus = getBaseStatus(ibcTransfer.status);
                    if (baseStatus === 'Pending') {
                        return transferId;
                    }
                    const message = getMessage(transferId);
                    removeMessage(transferId);
                    showMessage({
                        key: transferId,
                        type: baseStatus === 'Success' ? 'success' : 'error',
                        duration: 600000,
                        title: baseStatus === 'Success' ? 'Success' : 'Failure',
                        content: baseStatus === 'Success' ?
                            'Your transfer has completed successfully' :
                            'Your transfer has failed. Please check the explorer for details',
                        action: message?.action ? { ...message.action, close: true } : undefined,
                    });
                }));
                setListenedTransferIds(updatedListenedTransferIds.filter(Boolean) as string[]);
            }, 5000);
        });
    }, [ getFixedTransfer, getMessage, indexedTransfers, listenedTransferIds, removeMessage, showMessage ]);

    const getFixedFullTransfer = useCallback(async (
        transfer: IbcTransferDetails,
        client: StationClient,
    ): Promise<IbcTransferDetails | undefined> => {
        if (transfer.eibcFee) {
            transfer.eibcFeeCoins = (await getTransferEibcFeeCoins(transfer.eibcFee, client)) || undefined;
        }
        return { ...transfer };
    }, []);

    useEffect(() => {
        if (!hubNetwork || !hubNetworkData?.address || !initiatedTransfersLoading) {
            return;
        }
        loadInitiatedTransfers(hubNetwork.chainId, hubNetworkData.address)
            .then((transfers) => transfers.map(getFixedTransfer).filter(Boolean) as IbcTransferDetails[])
            .then(setAllInitiatedTransfers)
            .finally(() => setInitiatedTransfersLoading(false));
    }, [ getFixedTransfer, hubNetwork, hubNetworkData.address, initiatedTransfersLoading ]);

    useEffect(() => {
        if (!hubNetwork || !loading || !canLoadMore || (!showAll && !hubNetworkData?.address)) {
            return;
        }
        const lowerCaseSearchText = searchText.toLowerCase();
        const channels = !searchText ? [] : Object.values(hubChannelNetworkMap)
            .filter((network) =>
                network.chainName.toLowerCase().includes(lowerCaseSearchText) || network.chainId.toLowerCase() === lowerCaseSearchText)
            .map((network) => network.ibc?.hubChannel) as string[];
        const address = !showAll ? hubNetworkData.address : undefined;
        loadIbcTransfers(hubNetwork.chainId, PAGE_SIZE, page, statusesFilter, searchText, channels, address)
            .then(({ totalCount, transfers }) => {
                if (transfers.length < PAGE_SIZE) {
                    setCanLoadMore(false);
                }
                transfers = transfers.map(getFixedTransfer).filter(Boolean) as IbcTransferDetails[];
                setIndexedTransfers((currentTransfers) => !page ? transfers :
                    uniqBy([ ...currentTransfers, ...transfers ], (transfer) => transfer.id));
                setIndexedTotalCount(totalCount);
            })
            .finally(() => setLoading(false));
    }, [
        getFixedTransfer,
        canLoadMore,
        loading,
        hubChannelNetworkMap,
        hubNetwork,
        hubNetworkData.address,
        page,
        searchText,
        statusesFilter,
        showAll,
    ]);

    const initiatedTransfers = useMemo(() => {
        if (loading || initiatedTransfersLoading) {
            return [];
        }
        const lowerCaseSearchText = searchText.toLowerCase();
        const channels = !searchText ? [] : Object.values(hubChannelNetworkMap)
            .filter((network) =>
                network.chainName.toLowerCase().includes(lowerCaseSearchText) || network.chainId.toLowerCase() === lowerCaseSearchText)
            .map((network) => network.ibc?.hubChannel) as string[];
        return getFilteredInitiatedTransfers(allInitiatedTransfers, statusesFilter, searchText, channels)
            .filter((transfer) =>
                !indexedTransfers.some((indexedTransfer) => indexedTransfer.id === transfer.id))
            .sort((transfer1, transfer2) => transfer2.time - transfer1.time);
    }, [
        allInitiatedTransfers,
        hubChannelNetworkMap,
        indexedTransfers,
        initiatedTransfersLoading,
        loading,
        searchText,
        statusesFilter,
    ]);

    const transfers = useMemo(() => {
        if (!hubNetwork || initiatedTransfersLoading) {
            return [];
        }
        return [ ...initiatedTransfers, ...indexedTransfers ]
            .sort((transfer1, transfer2) => transfer2.time - transfer1.time)
            .filter(Boolean) as IbcTransferDetails[];
    }, [ hubNetwork, indexedTransfers, initiatedTransfers, initiatedTransfersLoading ]);

    const loadMore = useCallback(() => {
        if (!loading && canLoadMore) {
            setLoading(true);
            setPage(page + 1);
        }
    }, [ canLoadMore, loading, page ]);

    const listenToIbcTransfer = useCallback((transferId: string) => {
        setListenedTransferIds((transferIds) => [ ...transferIds, transferId ]);
    }, []);

    return (
        <IbcStatusContext.Provider
            value={{
                totalCount: indexedTotalCount + initiatedTransfers.length,
                transfers,
                allInitiatedTransfers,
                loading,
                initiatedTransfersLoading,
                initiatedTransferCreating,
                searchText,
                statusesFilter,
                loadMore,
                getFixedTransfer,
                getFixedFullTransfer,
                showAll,
                listenToIbcTransfer,
                setShowAll,
                refreshData: () => setToRefresh(true),
                setSearchText,
                setStatusesFilter,
                setInitiatedTransferCreating,
            }}
        >
            {children}
        </IbcStatusContext.Provider>
    );
};

import React, { useMemo } from 'react';
import Property from '../../../../../shared/components/property/property';
import StatisticsChange from '../../../../../shared/components/statistics/statistics-change/statistics-change';
import { formatPrice } from '../../../../../shared/utils/number-utils';
import { useAmm } from '../../../../amm/amm-context';
import { useNetwork } from '../../../../network/network-context';

const TotalMarketCapProperty: React.FC = () => {
    const { rollapps } = useNetwork();
    const { ammState } = useAmm();

    const { currentValue, previousValue } = useMemo(
        () => rollapps.reduce(({ currentValue, previousValue }, rollapp) => {
            const asset = ammState.assets?.find((asset) => asset.network.chainId === rollapp.chainId && asset.currency.type === 'main');
            const { amount = 0 } = rollapp.totalSupply?.value || {};
            const { amount: previousAmount = 0 } = rollapp.totalSupply?.previousDayValue || {};
            const currentMarketCap = (asset?.price || 0) * amount / Math.pow(10, asset?.currency.decimals || 0);
            const previousDayMarketCap = (asset?.previousDayPrice || 0) * previousAmount / Math.pow(10, asset?.currency.decimals || 0);
            return {
                currentValue: currentValue + currentMarketCap,
                previousValue: previousValue + previousDayMarketCap,
            };
        }, { currentValue: 0, previousValue: 0 }),
        [ ammState.assets, rollapps ],
    );

    return (
        <Property label='Total RollApps MarketCap'>
            <StatisticsChange period='day' currentValue={currentValue} previousValue={previousValue}>
                {ammState.params &&
                    formatPrice(currentValue, undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
            </StatisticsChange>
        </Property>
    );
};

export default TotalMarketCapProperty;

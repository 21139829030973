import React, { useMemo } from 'react';
import Property from '../../../../shared/components/property/property';
import Spinner from '../../../../shared/components/spinner/spinner';
import StatisticsChange from '../../../../shared/components/statistics/statistics-change/statistics-change';
import { formatPrice } from '../../../../shared/utils/number-utils';
import { useAmm } from '../../../amm/amm-context';
import { getMainCurrency } from '../../../currency/currency-service';
import { Network } from '../../network-types';

interface MarketCapPropertyProps {
    network: Network;
    fullyDiluted?: boolean;
}

const MarketCapProperty: React.FC<MarketCapPropertyProps> = ({ network, fullyDiluted }) => {
    const { ammState } = useAmm();

    const nativeAsset = useMemo(
        () => ammState.assets?.find((asset) => asset.network.chainId === network.chainId && asset.currency.type === 'main'),
        [ ammState.assets, network.chainId ],
    );

    const totalAmount = useMemo(() => {
        const { amount = 0, vesting = 0, onChainGov = 0 } = network.totalSupply?.value || {};
        return fullyDiluted ? amount : (amount - vesting - onChainGov);
    }, [ fullyDiluted, network.totalSupply?.value ]);

    const previousDayTotalAmount = useMemo(() => {
        const { amount = 0, vesting = 0, onChainGov = 0 } = network.totalSupply?.previousDayValue || {};
        return fullyDiluted ? amount : (amount - vesting - onChainGov);
    }, [ fullyDiluted, network.totalSupply?.previousDayValue ]);

    const currentMarketCap = useMemo(
        () => (nativeAsset?.price || 0) * totalAmount / Math.pow(10, getMainCurrency(network).decimals),
        [ nativeAsset?.price, network, totalAmount ],
    );

    const previousDayMarketCap = useMemo(
        () => (nativeAsset?.previousDayPrice || 0) * previousDayTotalAmount / Math.pow(10, getMainCurrency(network).decimals),
        [ nativeAsset?.previousDayPrice, network, previousDayTotalAmount ],
    );

    return (
        <Property label={fullyDiluted ? 'Total Supply Market Cap' : 'Market Cap'}>
            {!nativeAsset && (ammState.loading || ammState.paramsLoading || ammState.totalLockedValuesLoading) ?
                <Spinner /> : nativeAsset && (
                <StatisticsChange
                    period='day'
                    currentValue={currentMarketCap}
                    previousValue={previousDayMarketCap}
                >
                    {formatPrice(currentMarketCap, undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                </StatisticsChange>
            )}
        </Property>
    );
};

export default MarketCapProperty;


import React, { CSSProperties, MouseEvent, ReactNode } from 'react';
import classNames from 'classnames';
import { ReactComponent as ClearIcon } from '../../../../assets/icons/clear.svg';
import { ReactComponent as WarningIcon } from '../../../../assets/icons/warning.svg';
import { ReactComponent as ErrorIcon } from '../../../../assets/icons/error.svg';
import { ReactComponent as SuccessIcon } from '../../../../assets/icons/success.svg';
import Spinner from '../../spinner/spinner';
import { SnackbarMessage } from '../snackbar-types';
import Icon from '../../icon/icon';
import Button from '../../button/button';
import './snackbar-message-component.scss';

interface SnackbarMessageProps {
    message: SnackbarMessage;
    style?: CSSProperties;
    onRemoveClick?: () => void;
}

const SnackbarMessageComponent: React.FC<SnackbarMessageProps> = ({ message, style, onRemoveClick }) => {
    const onActionClick = (event: MouseEvent, message: SnackbarMessage): void => {
        event.stopPropagation();
        message.action?.callback();
        if (message.action?.close) {
            onRemoveClick?.();
        }
    };

    const renderMessageAction = (label: ReactNode, callback: (event: MouseEvent) => void): JSX.Element => {
        return <Button buttonType='secondary' size='small' className='message-action' onClick={callback}>{label}</Button>;
    };

    return (
        <div className={classNames('snackbar-message', message.type)} style={style}>
            {message.type === 'warning' && <Icon className='message-type-icon'><WarningIcon /></Icon>}
            {message.type === 'error' && <Icon className='message-type-icon'><ErrorIcon /></Icon>}
            {message.type === 'success' && <Icon className='message-type-icon'><SuccessIcon /></Icon>}
            {message.type === 'pending' && <Spinner className='message-type-icon' />}
            {message.prefix}
            <div
                className={classNames(
                    'message-content',
                    { 'have-prefix': Boolean(message.prefix), 'have-close-button': message.closeAction },
                )}
            >
                {message.title && <h5 className='message-title'><b>{message.title}</b></h5>}
                {message.content}
            </div>
            {message.action &&
                renderMessageAction(message.action.label, (event) => onActionClick(event, message))}
            {message.closeAction && (
                <Button className='close-action' onClick={onRemoveClick} buttonType='secondary' size='small'><ClearIcon /></Button>
            )}
        </div>
    );
};

export default SnackbarMessageComponent;

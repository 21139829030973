import { EncodeObject } from 'cosmjs/packages/proto-signing';
import React, { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useReducer } from 'react';
import { useCancelablePromise } from '../../../shared/hooks/use-cancelable-promise';
import { AccountNetworkState } from '../../account/account-network-state';
import { useAccountNetwork } from '../../account/use-account-network';
import { useClient } from '../../client/client-context';
import { ClientError } from '../../client/client-error';
import { getMainCurrency, getMaxDenomAmount, getMinDenomAmount, getStakingCurrency, isCoinsEquals } from '../../currency/currency-service';
import { CoinsAmount } from '../../currency/currency-types';
import { loadDelegations } from '../../staking/staking-service';
import { AmountTxState } from '../../tx/amount-tx/amount-tx-state';
import { useAmountTx } from '../../tx/amount-tx/use-amount-tx';
import { TxState } from '../../tx/tx-state';
import { useTx } from '../../tx/use-tx';
import { useWallet } from '../../wallet/wallet-context';
import { Network } from '../../network/network-types';
import {
    createDepositMessage,
    createVoteMessage,
    loadGovernanceParams,
    loadProposal,
    loadProposalTallyResult,
    loadVote,
} from '../governance-service';
import { GovernanceParams, Proposal, ProposalVoteOption, ProposalVotesSummary } from '../governance-types';
import { PROPOSAL_STATE_DEFAULT, proposalReducer, ProposalState } from './proposal-state';

interface ProposalContextProps {
    proposalId: number;
    network: Network;
    children: ReactNode;
}

interface ProposalContextValue {
    network: Network;
    proposalState: ProposalState;
    setVoteOption: (option: ProposalVoteOption, originalOption?: ProposalVoteOption) => void;
    setDeposit: (coins: CoinsAmount) => void;
    voteTxState: TxState;
    depositTxState: TxState;
    depositAmountTxState: AmountTxState;
    networkState: AccountNetworkState,
    depositAvailableBalances: CoinsAmount[],
    currentQuorum: number;
    broadcastVoteTx: () => void;
    broadcastDepositTx: () => void;
}

export const ProposalContext = createContext<ProposalContextValue>({} as ProposalContextValue);

export const useProposal = (): ProposalContextValue => useContext(ProposalContext);

export const ProposalContextProvider: React.FC<ProposalContextProps> = ({ proposalId, network, children }) => {
    const { networkWalletMap } = useWallet();
    const { clientStateMap, handleClientError } = useClient();
    const [ proposalState, proposalStateDispatch ] = useReducer(proposalReducer, PROPOSAL_STATE_DEFAULT);
    const [ networkState ] = useAccountNetwork(network);
    const cancelAndSetProposalPromise = useCancelablePromise<Proposal | undefined>();
    const cancelAndSetSummaryPromise = useCancelablePromise<ProposalVotesSummary | undefined>();
    const cancelAndSetVotesCountPromise = useCancelablePromise<number>();
    const cancelAndSetVoteOptionPromise = useCancelablePromise<ProposalVoteOption | undefined>();
    const cancelAndSetParamsPromise = useCancelablePromise<GovernanceParams>();

    const networkWallet = networkWalletMap[network.chainId];
    const clientState = clientStateMap[network.chainId];

    const voteMessagesCreator = useCallback((): EncodeObject[] => {
        if (!networkState.address) {
            return [];
        }
        return [ createVoteMessage(proposalId, networkState.address, proposalState.voteOption) ];
    }, [ networkState.address, proposalId, proposalState.voteOption ]);

    const depositMessagesCreator = useCallback((fee?: CoinsAmount, coins?: CoinsAmount): EncodeObject[] => {
        const balance = networkState.balances?.find((balance) => coins && isCoinsEquals(balance, coins));
        if (!networkState.address || !coins || !balance) {
            return [];
        }
        let baseAmountWithoutFee = undefined;
        if (fee && isCoinsEquals(coins, fee)) {
            coins = { ...coins, amount: Math.min(coins.amount, balance.amount - fee.amount) };
            baseAmountWithoutFee =
                balance.baseAmount ? balance.baseAmount - BigInt(getMinDenomAmount(fee.amount, fee.currency)) : undefined;
        }
        return [ createDepositMessage(proposalId, networkState.address, coins, baseAmountWithoutFee) ];
    }, [ networkState.address, networkState.balances, proposalId ]);

    const { txState: voteTxState, broadcast: broadcastVoteTx, calculateFee: calculateVoteTxFee } = useTx({
        networkState,
        txMessagesCreator: voteMessagesCreator,
    });

    const depositAvailableBalances = useMemo((): CoinsAmount[] => {
        const mainCurrency = getMainCurrency(network);
        const balance = networkState.balances?.find((balance) => isCoinsEquals(balance, { currency: mainCurrency, amount: 0 }));
        if (!balance) {
            return [ { currency: mainCurrency, amount: 0 } ];
        }
        return [ balance ];
    }, [ network, networkState.balances ]);

    const {
        txState: depositTxState,
        amountTxState: depositAmountTxState,
        broadcast: broadcastDepositTx,
        setCoins: setDeposit,
        calculateFee: calculateDepositTxFee,
    } = useAmountTx({
        networkState,
        availableBalances: depositAvailableBalances,
        amountTxMessagesCreator: depositMessagesCreator,
    });

    const setVoteOption = useCallback(
        (option: ProposalVoteOption, originalOption = proposalState.originalVoteOption) =>
            proposalStateDispatch({ type: 'set-vote-option', payload: { option, originalOption } }),
        [ proposalState.originalVoteOption ],
    );

    const currentQuorum = useMemo(() => {
        if (!network.totalSupply?.value?.bondedAmount || !proposalState.summary) {
            return 0;
        }
        const totalVotesBond = Object.values(proposalState.summary).reduce((current, option) => current + option.amount, 0);
        return totalVotesBond / getMaxDenomAmount(network.totalSupply?.value?.bondedAmount, getMainCurrency(network));
    }, [ network, proposalState.summary ]);

    useEffect(() => {
        if (networkWallet) {
            proposalStateDispatch({ type: 'set-vote-option-loading' });
        } else {
            proposalStateDispatch({ type: 'set-vote-option-loading', payload: undefined });
            proposalStateDispatch({ type: 'set-vote-option', payload: undefined });
        }
    }, [ networkWallet ]);

    useEffect(() => {
        if (!voteTxState.error) {
            return;
        }
        if (voteTxState.error instanceof ClientError) {
            handleClientError(voteTxState.error);
        } else {
            console.error(voteTxState.error);
        }
        calculateVoteTxFee(false);
    }, [ calculateVoteTxFee, handleClientError, voteTxState.error ]);

    useEffect(() => {
        if (!depositTxState.error) {
            return;
        }
        if (depositTxState.error instanceof ClientError) {
            handleClientError(depositTxState.error);
        } else {
            console.error(depositTxState.error);
        }
        calculateDepositTxFee(false);
    }, [ calculateDepositTxFee, depositTxState.error, handleClientError ]);

    useEffect(() => {
        if (networkState.address && proposalState.proposal?.status === 'Voting Period') {
            calculateVoteTxFee();
        }
    }, [ calculateVoteTxFee, networkState.address, proposalState.proposal?.status ]);

    useEffect(() => {
        if (networkState.address &&
            (proposalState.proposal?.status === 'Deposit Period' || proposalState.proposal?.status === 'Voting Period')) {
            calculateDepositTxFee();
        }
    }, [ calculateDepositTxFee, networkState.address, proposalState.proposal?.status ]);

    useEffect(() => {
        if (clientState && !clientState.client && !clientState.connecting) {
            proposalStateDispatch({ type: 'set-loading', payload: false });
            return;
        }
        if (!clientState?.client || clientState?.connecting) {
            return;
        }
        cancelAndSetProposalPromise(loadProposal(clientState.client, proposalId))
            .then((proposal) => proposalStateDispatch({ type: 'set-proposal', payload: proposal }))
            .catch((error) => {
                proposalStateDispatch({ type: 'set-loading', payload: false });
                handleClientError(error);
            });
    }, [ cancelAndSetProposalPromise, clientState, handleClientError, proposalId ]);

    useEffect(() => {
        if (clientState && !clientState.client && !clientState.connecting) {
            proposalStateDispatch({ type: 'set-summary-loading', payload: false });
            return;
        }
        if (!clientState?.client || clientState?.connecting) {
            return;
        }
        cancelAndSetSummaryPromise(loadProposalTallyResult(clientState.client, proposalId))
            .then((summary) => proposalStateDispatch({ type: 'set-summary', payload: summary }))
            .catch((error) => {
                proposalStateDispatch({ type: 'set-summary-loading', payload: false });
                handleClientError(error);
            });
    }, [ cancelAndSetSummaryPromise, clientState, handleClientError, proposalId ]);

    // useEffect(() => {
    //     if (clientState && !clientState.client && !clientState.connecting) {
    //         proposalStateDispatch({ type: 'set-votes-count-loading', payload: false });
    //         return;
    //     }
    //     if (!clientState?.client || clientState?.connecting) {
    //         return;
    //     }
    //     cancelAndSetVotesCountPromise(loadVotesCount(clientState.client, proposalId))
    //         .then((proposal) => proposalStateDispatch({ type: 'set-votes-count', payload: proposal }))
    //         .catch((error) => {
    //             proposalStateDispatch({ type: 'set-votes-count-loading', payload: false });
    //             handleClientError(error);
    //         });
    // }, [ cancelAndSetVotesCountPromise, clientState, handleClientError, proposalId ]);

    useEffect(() => {
        if (clientState && !clientState.client && !clientState.connecting) {
            proposalStateDispatch({ type: 'set-vote-option-loading', payload: false });
            return;
        }
        if (!clientState?.client || clientState?.connecting || !networkWallet || !networkState.address) {
            return;
        }
        cancelAndSetVoteOptionPromise(loadVote(clientState.client, proposalId, networkState.address))
            .then((option) => proposalStateDispatch({ type: 'set-vote-option', payload: option && { option, originalOption: option } }))
            .catch((error) => {
                proposalStateDispatch({ type: 'set-vote-option-loading', payload: false });
                if (!error.originalError?.message?.includes('not found for proposal')) {
                    handleClientError(error);
                }
            });
    }, [ cancelAndSetVoteOptionPromise, clientState, handleClientError, networkState.address, networkWallet, proposalId ]);

    useEffect(() => {
        if (clientState && !clientState.client && !clientState.connecting) {
            proposalStateDispatch({ type: 'set-params-loading', payload: false });
            return;
        }
        if (!clientState?.client || clientState?.connecting) {
            return;
        }
        cancelAndSetParamsPromise(loadGovernanceParams(clientState.client))
            .then((params) => proposalStateDispatch({ type: 'set-params', payload: params }))
            .catch((error) => {
                proposalStateDispatch({ type: 'set-params-loading', payload: false });
                handleClientError(error);
            });
    }, [ cancelAndSetParamsPromise, clientState, handleClientError ]);

    useEffect(() => {
        if (!clientState?.client ||
            clientState?.connecting ||
            !networkState.address ||
            proposalState.proposal?.status !== 'Voting Period'
        ) {
            return;
        }
        proposalStateDispatch({ type: 'set-can-vote-loading', payload: true });
        loadDelegations(clientState.client, getStakingCurrency(network), networkState.address)
            .then((delegations) => proposalStateDispatch({
                type: 'set-can-vote',
                payload: delegations.some((delegation) => delegation.coins.amount > 0),
            }))
            .catch((error) => {
                proposalStateDispatch({ type: 'set-can-vote-loading', payload: false });
                handleClientError(error);
            });
    }, [ clientState, handleClientError, network, networkState.address, proposalState.proposal?.status ]);

    return (
        <ProposalContext.Provider
            value={{
                network,
                currentQuorum,
                proposalState,
                voteTxState,
                depositTxState,
                depositAmountTxState,
                setVoteOption,
                networkState,
                depositAvailableBalances,
                setDeposit,
                broadcastVoteTx,
                broadcastDepositTx,
            }}
        >
            {children}
        </ProposalContext.Provider>
    );
};

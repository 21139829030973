import { Connection, PublicKey } from '@solana/web3.js';
import { getAccount, getAssociatedTokenAddress } from '@solana/spl-token';
import { Long } from 'cosmjs-types/helpers';
import Web3 from 'web3';
import { ethers } from 'ethers';
import { Network } from '../network/network-types';
import { convertToHexAddress } from '../wallet/wallet-service';
import { CoinsAmount, NetworkDenom } from '../currency/currency-types';
import { convertToCoinsAmount, getMaxDenomAmount, isCoinsEquals } from '../currency/currency-service';
import { StationClient } from '../client/station-clients/station-client';
import { ClientError } from '../client/client-error';
import { readStream } from '../../shared/utils/file-utils';
import { EthereumWallet } from '../wallet/wallets/ethereum-wallet';

export const getBalances = async (client: StationClient, address: string): Promise<CoinsAmount[]> => {
    const network = client.getNetwork();
    const { balances } = await client.getBankQueryClient().AllBalances({
        address,
        pagination: {
            reverse: false,
            limit: Long.MAX_VALUE,
            offset: Long.fromNumber(0),
            countTotal: false,
            key: new Uint8Array(0),
        },
    }).catch((error) => {
        throw new ClientError('FETCH_DATA_FAILED', network, error);
    });

    const fixedBalances = await Promise.all(balances.filter((balance) => !balance.denom.includes('gamm/pool'))
        .map((coin) => convertToCoinsAmount(coin, client)));

    if (network.evm) {
        const erc20Balances = await getERC20Balances(client, address);
        fixedBalances.push(...erc20Balances.filter((balance) => balance.amount));
    }

    const otherNetworkBalances: CoinsAmount[] = network.currencies
        .filter((currency) => currency &&
            fixedBalances.every((coins) => !coins || !isCoinsEquals(coins, { currency, amount: 0 })))
        .map((currency) => ({ currency, amount: 0 })) as CoinsAmount[];
    fixedBalances.push(...otherNetworkBalances);

    return (fixedBalances.filter(Boolean) as CoinsAmount[]).sort((balance1, balance2) =>
        ((balance2?.amount ? 1 : 0) - (balance1?.amount ? 1 : 0)) || ((balance1?.ibc ? 1 : 0) - (balance2?.ibc ? 1 : 0)));
};

export const getERC20Tokens = async (client: StationClient): Promise<CoinsAmount[]> => {
    const network = client.getNetwork();
    if (!network.rest || network.type === 'Hub') {
        return [];
    }
    const tokenPairResponse = await fetch(`${network.rest}/evmos/erc20/v1/token_pairs`).catch(() => null);
    if (!tokenPairResponse?.ok) {
        return [];
    }
    const tokenPairResponseText = tokenPairResponse?.body ? await readStream(tokenPairResponse.body) : undefined;
    const tokenPairs = ((JSON.parse(tokenPairResponseText || '{}')?.token_pairs || []) as { erc20_address: string, denom: string }[])
        .map(({ erc20_address, denom }) => ({ erc20Address: erc20_address, denom }));

    const tokens = await Promise.all(tokenPairs.map(async (tokenPair) => {
        const coins = await convertToCoinsAmount({ amount: '0', denom: tokenPair.denom }, client);
        return coins ? { ...coins, erc20Address: tokenPair.erc20Address } : coins;
    }));
    return tokens.filter(Boolean) as CoinsAmount[];
};

export const getERC20Balances = async (client: StationClient, address: string): Promise<CoinsAmount[]> => {
    const network = client.getNetwork();
    if (!network.evm?.rpc) {
        return [];
    }
    const tokens = await getERC20Tokens(client);
    if (!tokens.length) {
        return [];
    }
    const Web3Client = new Web3(new Web3.providers.HttpProvider(network.evm.rpc));
    const balanceOfABI = [
        {
            constant: true,
            inputs: [ { name: '_owner', type: 'address' } ],
            name: 'balanceOf',
            outputs: [ { name: 'balance', type: 'uint256' } ],
            payable: false,
            stateMutability: 'view',
            type: 'function',
        },
    ];
    const walletAddress = convertToHexAddress(address);
    return Promise.all(tokens.map(async (coins) => {
        const contract = new Web3Client.eth.Contract(balanceOfABI as any, coins.erc20Address);
        const balance = await contract.methods.balanceOf(walletAddress).call();
        return { ...coins, amount: getMaxDenomAmount(Number(balance), coins.currency), baseAmount: BigInt(balance) };
    }));
};

export const getEvmBalances = async (
    network: Network,
    hexAddress: string,
    wallet: EthereumWallet,
    networkDenoms?: NetworkDenom[],
): Promise<CoinsAmount[]> => {
    await wallet.switchNetwork(network);
    const provider = new ethers.BrowserProvider(await wallet.getProvider());
    const balanceOfABI = [
        {
            constant: true,
            inputs: [ { name: '_owner', type: 'address' } ],
            name: 'balanceOf',
            outputs: [ { name: 'balance', type: 'uint256' } ],
            payable: false,
            stateMutability: 'view',
            type: 'function',
        },
    ];
    return Promise.all(network.currencies.map(async (currency) => {
        let balance;
        if (currency.type === 'main') {
            balance = Number(await provider.getBalance(hexAddress));
        } else {
            const contract = new ethers.Contract(currency.bridgeDenom || '', balanceOfABI as any, provider);
            balance = Number(await contract.balanceOf(hexAddress));
        }
        let ibc: CoinsAmount['ibc'] | undefined = undefined;
        if (currency.ibcRepresentation) {
            const networkDenom = networkDenoms?.find((networkDenom) => networkDenom.denom === currency.ibcRepresentation);
            ibc = {
                representation: currency.ibcRepresentation,
                networkId: networkDenom?.ibcNetworkId || (currency.type === 'main' ? network.chainId : ''),
                path: networkDenom?.path || '',
            };
        }
        return { currency, amount: getMaxDenomAmount(balance, currency), ibc };
    }));
};

export const getSolanaBalances = async (
    network: Network,
    address: string,
    networkDenoms?: NetworkDenom[],
): Promise<CoinsAmount[]> => {
    if (!network.rpc) {
        return [];
    }
    const connection = new Connection(network.rpc);
    const payerKey = new PublicKey(address);
    return Promise.all(network.currencies
        .filter((currency) => currency.solanaMintAccount && currency.ibcRepresentation)
        .map(async (currency) => {
            const ata = await getAssociatedTokenAddress(new PublicKey(currency.solanaMintAccount || ''), payerKey);
            const accountData = await getAccount(connection, ata, 'confirmed');
            const amount = Number(accountData.amount) || 0;
            const networkDenom = networkDenoms?.find((networkDenom) => networkDenom.denom === currency.ibcRepresentation);
            const ibc: CoinsAmount['ibc'] = {
                representation: currency.ibcRepresentation || '',
                networkId: networkDenom?.ibcNetworkId || network.chainId,
                path: networkDenom?.path || '',
            };
            return { currency, amount: getMaxDenomAmount(amount, currency), ibc };
        }));
};

import React, { useMemo } from 'react';
import Property from '../../../../shared/components/property/property';
import StatisticsChange from '../../../../shared/components/statistics/statistics-change/statistics-change';
import { formatNumber } from '../../../../shared/utils/number-utils';
import { useNetwork } from '../../network-context';

const TotalIbcTransfersProperty: React.FC = () => {
    const { rollapps } = useNetwork();

    const { currentValue, diffWeek } = useMemo(
        () => rollapps.reduce(({ currentValue, diffWeek }, rollapp) => ({
            currentValue: currentValue + (rollapp.ibcTransfers?.value.totalIn || 0) + (rollapp.ibcTransfers?.value.totalOut || 0),
            diffWeek: diffWeek + (rollapp.ibcTransfers?.diffWeek || 0),
        }), { currentValue: 0, diffWeek: 0 }),
        [ rollapps ],
    );

    return (
        <Property label='Total RollApps IBC transfers (7d)'>
            <StatisticsChange period='week' currentValue={currentValue} previousValue={currentValue - diffWeek}>
                {formatNumber(diffWeek)}
            </StatisticsChange>
        </Property>
    );
};

export default TotalIbcTransfersProperty;



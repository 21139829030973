import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { EncodeObject } from 'cosmjs/packages/proto-signing';
import { useValidatorList, ValidatorListData } from './validator/validator-list/use-validator-list';
import { useStakingData } from './staking-data/use-staking-data';
import { createRewardMessage } from './staking-service';
import { useTx } from '../tx/use-tx';
import { TxState } from '../tx/tx-state';
import { StakingDataState } from './staking-data/staking-data-state';
import { ClientError } from '../client/client-error';
import { useClient } from '../client/client-context';
import { Validator, ValidatorsType } from './validator/validator-types';
import { Network } from '../network/network-types';
import { useAccountNetwork } from '../account/use-account-network';
import { AccountNetworkState } from '../account/account-network-state';

interface StakingContextValue {
    stakingDataState?: StakingDataState;
    totalValidatorsData?: ValidatorListData;
    stakedValidatorsData?: ValidatorListData;
    unstakingValidatorsData?: ValidatorListData;
    rewardsTxState?: TxState;
    network: Network;
    networkState: AccountNetworkState;
    validatorLogos?: string[];
    stakeValidatorsType: ValidatorsType;
    withdrawRewards: (validator?: Validator) => void;
    setStakeValidatorsType: (type: ValidatorsType) => void;
}

export const StakingContext = createContext<StakingContextValue>({} as StakingContextValue);

export const useStaking = (): StakingContextValue => useContext(StakingContext);

export const StakingContextProvider = ({
    children,
    network,
    types = [ 'All', 'Staked', 'Unstaking' ],
}: { children: ReactNode, network: Network, types?: ValidatorsType[] }) => {
    const { handleClientError } = useClient();
    const [ networkState ] = useAccountNetwork(network);
    const { stakingDataState } = useStakingData(network, networkState);
    const totalValidatorsData = useValidatorList(network, networkState, 'All', stakingDataState, types.includes('All'));
    const stakedValidatorsData = useValidatorList(network, networkState, 'Staked', stakingDataState, types.includes('Staked'));
    const unstakingValidatorsData = useValidatorList(network, networkState, 'Unstaking', stakingDataState, types.includes('Unstaking'));
    const [ stakeValidatorsType, setStakeValidatorsType ] = useState<ValidatorsType>('Staked');
    const [ rewardsTargetValidator, setRewardsTargetValidator ] = useState<Validator>();

    const rewardsMessagesCreator = useCallback((): EncodeObject[] => {
        if (!networkState.address) {
            return [];
        }
        const notEmptyRewards = stakingDataState.rewards?.filter((reward) => Boolean(reward.coins.amount)) || [];
        return notEmptyRewards
            .map((reward) => reward.validatorAddress)
            .filter((address) => !rewardsTargetValidator || rewardsTargetValidator.address === address)
            .map((validatorAddress) => createRewardMessage(networkState.address || '', validatorAddress));
    }, [ networkState.address, rewardsTargetValidator, stakingDataState.rewards ]);

    const { txState: rewardsTxState, broadcast, calculateFee } = useTx({
        networkState: networkState,
        txMessagesCreator: rewardsMessagesCreator,
    });

    const withdrawRewards = useCallback((validator?: Validator) => {
        if (!validator) {
            broadcast().then();
        } else {
            setRewardsTargetValidator(validator);
        }
    }, [ broadcast ]);

    useEffect(() => {
        if (rewardsTxState.broadcasting) {
            setRewardsTargetValidator(undefined);
        }
    }, [ rewardsTxState.broadcasting ]);

    useEffect(() => {
        if (rewardsTargetValidator) {
            broadcast().then();
        }
    }, [ broadcast, rewardsTargetValidator ]);

    useEffect(() => {
        if (!rewardsTxState.error) {
            return;
        }
        if (rewardsTxState.error instanceof ClientError) {
            handleClientError(rewardsTxState.error);
        } else {
            console.error(rewardsTxState.error);
        }
        calculateFee(false);
    }, [ calculateFee, handleClientError, rewardsTxState.error ]);

    return (
        <StakingContext.Provider
            value={{
                network,
                networkState,
                stakingDataState,
                totalValidatorsData,
                stakedValidatorsData,
                unstakingValidatorsData,
                stakeValidatorsType,
                rewardsTxState,
                withdrawRewards,
                setStakeValidatorsType,
            }}
        >
            {children}
        </StakingContext.Provider>
    );
};

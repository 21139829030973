import { EncodeObject } from 'cosmjs/packages/proto-signing';
import { useCallback, useEffect, useState } from 'react';
import { AccountNetworkState } from '../../../account-network-state';
import { useClient } from '../../../../client/client-context';
import { ClientError } from '../../../../client/client-error';
import { getMinDenomAmount, isCoinsEquals } from '../../../../currency/currency-service';
import { CoinsAmount } from '../../../../currency/currency-types';
import { AmountTxValue, useAmountTx } from '../../../../tx/amount-tx/use-amount-tx';
import { useWallet } from '../../../../wallet/wallet-context';
import { WalletError } from '../../../../wallet/wallet-error';
import { convertToBech32Address } from '../../../../wallet/wallet-service';
import { createSendMessage } from './send-funds-service';

interface SendFundsValue extends Omit<AmountTxValue, 'calculateFee' | 'clearFee' | 'txStateDispatch'> {
    destinationAddress: string,
    setDestinationAddress: (value: string) => void;
}

export const useSendFunds = (networkState: AccountNetworkState): SendFundsValue => {
    const { handleClientError } = useClient();
    const { handleWalletError } = useWallet();
    const [ destinationAddress, setDestinationAddress ] = useState('');

    const sendMessagesCreator = useCallback((fee?: CoinsAmount, coins?: CoinsAmount): EncodeObject[] => {
        const balance = networkState.balances?.find((balance) => coins && isCoinsEquals(balance, coins));
        if (!coins ||
            !networkState.network ||
            !networkState.address ||
            !networkState.network.bech32Prefix ||
            !balance) {
            return [];
        }
        let baseAmountWithoutFee = undefined;
        if (fee && isCoinsEquals(coins, fee)) {
            coins = { ...coins, amount: Math.min(coins.amount, balance.amount - fee.amount) };
            baseAmountWithoutFee =
                balance.baseAmount ? balance.baseAmount - BigInt(getMinDenomAmount(fee.amount, fee.currency)) : undefined;
        }
        const toAddress = !destinationAddress.startsWith('0x') ? destinationAddress :
            convertToBech32Address(destinationAddress, networkState.network.bech32Prefix);
        const message = createSendMessage(networkState.address, toAddress || networkState.address, coins, balance, baseAmountWithoutFee);
        return [ message ];
    }, [ destinationAddress, networkState.address, networkState.balances, networkState.network ]);

    const { txState, amountTxState, evmContract, setCoins, setAmount, calculateFee, clearFee, broadcast } = useAmountTx({
        networkState: networkState,
        amountTxMessagesCreator: sendMessagesCreator,
        useEvm: networkState.network?.type !== 'Hub',
    });

    const handleError = useCallback((error: any): void => {
        if (!error) {
            return;
        }
        if (error instanceof ClientError) {
            handleClientError(error);
        } else if (error instanceof WalletError) {
            handleWalletError(error);
        } else {
            console.error(error);
        }
        calculateFee(false);
    }, [ calculateFee, handleClientError, handleWalletError ]);

    useEffect(() => handleError(txState.error), [ handleError, txState.error ]);

    useEffect(() => {
        if (txState.response) {
            setDestinationAddress('');
        }
    }, [ txState.response ]);

    useEffect(() => {
        if (networkState.network) {
            calculateFee();
        } else {
            clearFee();
        }
    }, [ calculateFee, clearFee, networkState.network ]);

    return { destinationAddress, txState, amountTxState, evmContract, setDestinationAddress, setCoins, broadcast, setAmount };
};

import React, { forwardRef, ReactElement, ReactNode } from 'react';
import classNames from 'classnames';
import { validateAndGetChildrenAsArray } from '../../../utils/react-utils';
import Menu, { MenuAction, MenuProps, MenuRefProps } from '../../menu/menu';
import './options-modal.scss';

export interface OptionProps {
    children: ReactNode;
    disabled?: boolean;
    value: string | number;
    iconColorMode?: 'fill' | 'stroke' | 'original';
    className?: string;
    group?: string;
}

export interface OptionsModalProps extends Omit<MenuProps, 'children'> {
    onOptionSelect?: (option: OptionProps) => void;
    selectedValue?: string | number;
    children: ReactNode;
}

export const Option: React.FC<OptionProps> = ({ children }) => <>{children}</>;

const OptionsModal: React.ForwardRefRenderFunction<MenuRefProps, OptionsModalProps> = ({
    children,
    selectedValue,
    onOptionSelect,
    ...menuProps
}, menuRef) => {
    const options = validateAndGetChildrenAsArray(children, Option);

    const onSelectOption = (event: React.MouseEvent, option: OptionProps): void => {
        event.stopPropagation();
        onOptionSelect?.(option);
    };

    return (
        <Menu {...menuProps} className={classNames('options-modal form-control', menuProps.className)} ref={menuRef}>
            {options.map((option) => (
                <MenuAction
                    disabled={option.props.disabled}
                    className={classNames('option', option.props.className, { active: option.props.value === selectedValue })}
                    iconColorMode={option.props.iconColorMode}
                    key={option.props.value}
                    onClick={(event) => onSelectOption(event, option.props)}
                    group={option.props.group}
                >
                    {option}
                </MenuAction>
            ))}
        </Menu>
    );
};

export default forwardRef(OptionsModal);


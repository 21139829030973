import { Coin } from 'cosmjs-types/cosmos/base/v1beta1/coin';
import BigNumber from 'bignumber.js';
import { CoinsAmount, Currency, CurrencyType, NetworkDenom } from './currency-types';
import { ChannelNetworkMap, Network } from '../network/network-types';
import { StationClient } from '../client/station-clients/station-client';
import { getNetworkLogoPath } from '../network/network-service';

export const getCurrencyLogoPath = (currency: Currency, network: Network) => {
    if (!currency.logo) {
        return getNetworkLogoPath(network);
    }
    if (/^https?:\/\//i.test(currency.logo)) {
        return currency.logo;
    }
    return network.custom ?
        require('../../assets' + currency.logo) :
        `${process.env.REACT_APP_ROLLAPP_REGISTRY_RAW_BASE_URL}/${network.chainId}${currency.logo}`;
};

export const convertToCoinsAmount = async (coin: Coin, client: StationClient, waitToNetworkDenoms = true): Promise<CoinsAmount | null> => {
    const network = client.getNetwork();
    let ibc: CoinsAmount['ibc'];
    let currency = getCurrency(network, coin.denom);
    if (waitToNetworkDenoms && !client.getHubNetworkDenoms()) {
        return null;
    }
    if (!currency) {
        let networkDenom = client.getHubNetworkDenoms()?.find((networkDenom) => networkDenom.denom === coin.denom);
        if (!networkDenom) {
            const response = await client.getIbcTransferQueryClient()?.DenomTrace({ hash: coin.denom }).catch((error) => {
                console.error(`Can't fetch denom trace for: ${coin.denom}`, error);
                return null;
            });
            networkDenom = (response?.denomTrace || {}) as NetworkDenom;
        }
        const { path, baseDenom } = networkDenom;
        if (!path || !baseDenom) {
            return null;
        }
        const ibcSourceNetwork = getIbcSourceNetwork(network, client.getHubNetwork(), client.getHubChannelNetworkMap(), path);
        currency = ibcSourceNetwork && getCurrency(ibcSourceNetwork, baseDenom);
        if (!currency || !ibcSourceNetwork) {
            return null;
        }
        ibc = { representation: coin.denom, path, networkId: ibcSourceNetwork.chainId };
    }
    const amount = getMaxDenomAmount(Number(coin.amount) || 0, currency);
    try {
        return { amount, currency, ibc, baseAmount: BigInt(coin.amount) };
    } catch {
        return { amount, currency, ibc, baseAmount: BigInt(Number(coin.amount)) };
    }
};

export const getIbcSourceNetwork = (
    network: Network,
    hubNetwork: Network,
    hubChannelNetworkMap: ChannelNetworkMap,
    path: string,
): Network | undefined => {
    const pathParts = path.split(/\/?transfer\//).filter(Boolean);
    if (!pathParts.length || pathParts.length > 2 ||
        (network.type === 'Hub' && pathParts.length === 2) ||
        (network.type !== 'Hub' && pathParts[0] !== network.ibc?.channel)) {
        return;
    }
    if (network.type === 'Hub') {
        return hubChannelNetworkMap[pathParts[0]];
    } else if (pathParts.length === 1) {
        return hubNetwork;
    } else {
        return hubChannelNetworkMap[pathParts[1]];
    }
};

export const convertToCoin = (coins: CoinsAmount, ibcRepresentation?: string, minCoinsAmount: number = 1): Coin => {
    const amount = (getMinDenomAmount(coins.amount, coins.currency) || minCoinsAmount).toLocaleString(undefined, { useGrouping: false });
    const denom = ibcRepresentation || coins.currency.baseDenom;
    return { amount, denom };
};

export const getMaxDenomAmount = (amount: number, currency: Currency): number => {
    return Math.round(amount + Number.EPSILON) / Math.pow(10, currency.decimals);
};

export const getMinDenomAmount = (amount: number, currency: Currency): number => {
    return Math.round(new BigNumber(10).pow(currency.decimals).times(amount).toNumber());
};

export const getCurrency = (network: Network, denom: string): Currency | undefined => {
    return network.currencies.find((currency) => currency.baseDenom === denom);
};

export const getMainCurrency = (network: Network): Currency => {
    return getCurrencyByType(network, 'main') || getCurrencyByType(network, 'regular') || network.currencies[0];
};

export const getStakingCurrency = (network: Network): Currency => {
    return getCurrencyByType(network, 'staking') || getMainCurrency(network);
};

export const getFeeCurrency = (network: Network): Currency => {
    return getCurrencyByType(network, 'fee') || getMainCurrency(network);
};

export const isCoinsEquals = (coins1: CoinsAmount, coins2: CoinsAmount): boolean => {
    return Boolean((coins1.currency.baseDenom === coins2.currency.baseDenom && coins1.ibc?.networkId === coins2.ibc?.networkId) ||
        (coins1.currency.ibcRepresentation && coins1.currency.ibcRepresentation === coins2.currency.ibcRepresentation) ||
        (coins2.currency.ibcRepresentation && coins1.ibc?.representation === coins2.currency.ibcRepresentation) ||
        (coins1.currency.ibcRepresentation && coins1.currency.ibcRepresentation === coins2.ibc?.representation));
};

export const isDenomsEquals = (coins1: CoinsAmount, baseDenom: string): boolean => {
    return coins1.ibc?.representation === baseDenom || coins1.currency.baseDenom === baseDenom;
};

const getCurrencyByType = (network: Network, type: CurrencyType): Currency | undefined => {
    return network.currencies.find((currency) => currency.type === type);
};

import React, { useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Governance from '../governance/governance';
import PathNav, { PathNavItem } from '../path-nav/path-nav';
import NetworkApps from './network-apps/network-apps';
import NetworkHeader from './network-header/network-header';
import { useAccountNetwork } from '../account/use-account-network';
import TabBar, { Tab } from '../../shared/components/tabs/tab-bar';
import { useNetworkDashboard } from './network-dashboard-context';
import NetworkStaking from './network-staking/network-staking';
import { NetworkSummary } from './network-summary/network-summary';
import './network-dashboard-page.scss';

const NetworkDashboardPage: React.FC = () => {
    const navigate = useNavigate();
    const { network } = useNetworkDashboard();
    const [ networkState ] = useAccountNetwork(network);

    const { pageKey } = useParams();

    const isPageKeyExists = useMemo(
        () => pageKey && [ 'metrics', 'staking', 'governance', 'proposal', ...(network.apps?.length ? [ 'apps' ] : []) ].includes(pageKey),
        [ network.apps?.length, pageKey ],
    );

    useEffect(() => {
        if (!isPageKeyExists) {
            navigate(pageKey ? '../metrics' : './metrics', { relative: 'path', replace: true });
        }
    }, [ isPageKeyExists, navigate, pageKey ]);

    if (!isPageKeyExists) {
        return null;
    }

    return (
        <div className='page network-dashboard'>
            {network.type === 'RollApp' && (
                <PathNav>
                    <PathNavItem label='RollApps' url='/rollapps' />
                    <PathNavItem label={network.chainName} />
                </PathNav>
            )}

            <NetworkHeader className='dashboard-network-header' networkState={networkState} />

            <TabBar
                className='dashboard-tab-bar'
                activeTabKey={pageKey === 'proposal' ? 'governance' : pageKey}
                onTabChange={(tabKey) => navigate('../' + tabKey, { relative: 'path' })}
            >
                <Tab label='Metrics' tabKey='metrics' className='dashboard-tab'>
                    <NetworkSummary />
                </Tab>
                {network.type === 'RollApp' && network.apps?.length && (
                    <Tab label='Apps' tabKey='apps' className='dashboard-tab'>
                        <NetworkApps />
                    </Tab>
                )}
                <Tab label='Staking' tabKey='staking' className='dashboard-tab'>
                    <NetworkStaking />
                </Tab>
                <Tab label='Governance' tabKey='governance' className='dashboard-tab'>
                    <Governance network={network} />
                </Tab>
            </TabBar>
        </div>
    );
};

export default NetworkDashboardPage;

import { useEffect, useState } from 'react';
import { useClient } from '../../../client/client-context';
import { useNetwork } from '../../../network/network-context';
import { useIbcStatus } from '../ibc-status-context';
import { loadIbcTransfer } from '../ibc-status-service';
import { IbcTransferDetails } from '../ibc-status-types';

export interface IbcTransferStatusValue {
    transfer?: IbcTransferDetails;
    loading: boolean;
}

interface IbcTransferStatusParams {
    transferId?: string;
}

export const useIbcTransferStatus = ({ transferId }: IbcTransferStatusParams): IbcTransferStatusValue => {
    const { hubNetwork } = useNetwork();
    const { clientStateMap } = useClient();
    const { allInitiatedTransfers, transfers, loading: ibcStatusLoading, getFixedTransfer, getFixedFullTransfer } = useIbcStatus();
    const [ transfer, setTransfer ] = useState<IbcTransferDetails>();
    const [ loading, setLoading ] = useState(false);

    const clientState = hubNetwork?.chainId ? clientStateMap[hubNetwork.chainId] : undefined;

    useEffect(() => {
        if (!transferId || ibcStatusLoading) {
            return;
        }
        const existingTransfer = [ ...allInitiatedTransfers, ...transfers ].find((transfer) => transfer.id === transferId);
        if (existingTransfer) {
            setTransfer(existingTransfer);
            return;
        }
        setLoading(true);
        loadIbcTransfer(transferId).then((transfer) => setTransfer(transfer && getFixedTransfer(transfer)));
    }, [ allInitiatedTransfers, clientState?.client, getFixedTransfer, ibcStatusLoading, transfer, transferId, transfers ]);

    useEffect(() => {
        if (clientState && !clientState.client && !clientState.connecting) {
            setLoading(false);
            return;
        }
        if (!transfer || !clientState?.client || clientState?.connecting || transfer.eibcFeeCoins) {
            return;
        }
        getFixedFullTransfer(transfer, clientState.client).then(setTransfer).finally(() => setLoading(false));
    }, [ clientState, getFixedFullTransfer, transfer ]);

    return { transfer, loading: loading || ibcStatusLoading };
};

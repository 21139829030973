import React from 'react';
import StatisticsCards from '../../../../shared/components/statistics-cards/statistics-cards';
import TotalAddressesCountProperty from './properties/total-addresses-count-property';
import TotalIbcTransfersProperty from '../../../network/statistics/properties/total-ibc-transfers-property';
import TotalMarketCapProperty from './properties/total-market-cap-property';
import RollappsCountProperty from '../../../hub/statistics/rollapps-count-property';
import { useRollapps } from '../rollapps-context';
import './rollapps-statistics.scss';

const RollappsStatistics: React.FC = () => {
    const { hubAnalyticsState } = useRollapps();

    return (
        <StatisticsCards className='rollapps-statistics-cards'>
            <RollappsCountProperty state={hubAnalyticsState} />
            <TotalMarketCapProperty />
            <TotalIbcTransfersProperty />
            <TotalAddressesCountProperty />
        </StatisticsCards>
    );
};

export default RollappsStatistics;

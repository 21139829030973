import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Tooltip from '../../../shared/components/tooltip/tooltip';
import useScrollPosition from '../../../shared/hooks/use-scroll-position';
import { useAmm } from '../../amm/amm-context';
import { Network } from '../../network/network-types';
import { formatNumber, formatPrice } from '../../../shared/utils/number-utils';
import Button from '../../../shared/components/button/button';
import Table, { TableColumn, TableRow } from '../../../shared/components/table/table';
import { getMainCurrency, getMaxDenomAmount, isCoinsEquals } from '../../currency/currency-service';
import { ReactComponent as ArrowUpRight } from '../../../assets/icons/arrow-up-right.svg';
import { ReactComponent as UploadIcon } from '../../../assets/icons/upload.svg';
import { ReactComponent as WebsiteLinkIcon } from '../../../assets/icons/globe.svg';
import Spinner from '../../../shared/components/spinner/spinner';
import RollappsHighlights from './rollapp-highlights/rollapp-highlights';
import { RollappsContextProvider, useRollapps } from './rollapps-context';
import IbcTransferDialog from '../../ibc-transfer/ibc-transfer-dialog/ibc-transfer-dialog';
import AvailabilityIndicator from '../availability-indicator/availability-indicator';
import StatisticsChange from '../../../shared/components/statistics/statistics-change/statistics-change';
import {
    getCompareValues,
    getHistoryValuesInPeriod,
} from '../../../shared/components/statistics/statistics-change/statistics-change-service';
import SimpleLineChart from '../../network/statistics/charts/simple-line-chart/simple-line-chart';
import RollappsStatistics from './rollapps-statistics/rollapps-statistics';
import AddCustomRollappDialog from '../add-custom-rollapp/add-custom-rollapp-dialog/add-custom-rollapp-dialog';
import { useNetwork } from '../../network/network-context';
import { getNetworkLogoPath } from '../../network/network-service';
import './rollapps-page.scss';

const RollappsPage: React.FC = () => {
    const navigate = useNavigate();
    const { rollapps, loading, hubNetwork, hubCurrency } = useNetwork();
    const { rollappsAnalyticsState, sortedRollapps, loadMore } = useRollapps();
    const { ammState } = useAmm();
    const [ addCustomDialogOpen, setAddCustomDialogOpen ] = useState(false);
    const [ depositRollapp, setDepositRollapp ] = useState<Network>();
    const scrollPosition = useScrollPosition();

    const rollappsTvlCompareValues = useMemo<{ [rollappId: string]: { currentValue: number, previousValue: number } }>(() => {
        return rollapps.reduce((current, rollapp) => {
            const data = rollappsAnalyticsState.analyticsMap?.[rollapp.chainId]?.totalSupply;
            if (!data || !hubCurrency) {
                return current;
            }
            const { currentValue, previousValue } = getCompareValues(data, 'week', undefined, ({ tvl }) => tvl);
            return { ...current, [rollapp.chainId]: { currentValue, previousValue } };
        }, {});
    }, [ hubCurrency, rollapps, rollappsAnalyticsState.analyticsMap ]);

    useEffect(() => {
        if (scrollPosition.target?.scrollTop &&
            scrollPosition.target.scrollTop + 1.5 * scrollPosition.target.clientHeight >= scrollPosition.target.scrollHeight
        ) {
            loadMore();
        }
    }, [ loadMore, scrollPosition.target?.clientHeight, scrollPosition.target?.scrollHeight, scrollPosition.target?.scrollTop ]);

    const onWebsiteClick = (website: string, event: React.MouseEvent): void => {
        event.stopPropagation();
        window.open(website, '_blank');
    };

    const renderRollappNameColumn = (rollapp: Network): ReactElement => {
        const currency = getMainCurrency(rollapp);

        return (
            <TableColumn className='name-column'>
                <img className='network-logo' src={getNetworkLogoPath(rollapp)} alt='network-logo' />
                <div className='rollapp-details'>
                    <div className='rollapp-name-container'>
                        <span className='rollapp-name'>{rollapp.chainName}</span>
                        <div className='rollapp-info'>
                            {currency?.displayDenom}
                            <AvailabilityIndicator
                                containerClassName='availability-indicator-container'
                                status={rollapp.availabilityStatus?.value}
                            />
                            {rollapp.website && (
                                <Button
                                    buttonType='icon'
                                    className='website-link-indicator'
                                    onClick={(event) => rollapp.website ? onWebsiteClick(rollapp.website, event) : null}
                                >
                                    <WebsiteLinkIcon />
                                </Button>
                            )}
                            {rollapp.goldberg && (
                                <Tooltip title='Participating in Avail Goldberg Testnet' placement='top'>
                                    <img
                                        onClick={(event) => event.stopPropagation()}
                                        className='avail-goldberg-logo'
                                        src={require('../../../assets/logos/avail-goldberg-logo.png')}
                                        alt='avail-goldberg-logo'
                                    />
                                </Tooltip>
                            )}
                        </div>
                    </div>
                </div>
            </TableColumn>
        );
    };

    const renderIbcTransfersColumn = (rollapp: Network): ReactElement => {
        const currentValue = (rollapp.ibcTransfers?.value?.totalIn || 0) + (rollapp.ibcTransfers?.value?.totalOut || 0);
        const previousValue = currentValue - (rollapp.ibcTransfers?.diffWeek || 0);

        return (
            <TableColumn align='right'>
                <StatisticsChange className='analytics-change' period='week' currentValue={currentValue} previousValue={previousValue}>
                    {formatNumber(rollapp.ibcTransfers?.diffWeek || 0)}
                </StatisticsChange>
            </TableColumn>
        );
    };

    const renderTvlColumn = (rollapp: Network): ReactElement => {
        const { currentValue = 0, previousValue = 0 } = rollappsTvlCompareValues[rollapp.chainId] || {};

        return (
            <TableColumn align='right' contentClassName='total-tvl-column-content'>
                {!rollappsTvlCompareValues[rollapp.chainId] && rollappsAnalyticsState.loadingMap?.[rollapp.chainId] ?
                    <Spinner /> :
                    <StatisticsChange period='week' currentValue={currentValue} previousValue={previousValue}>
                        {ammState.params &&
                            formatNumber(getMaxDenomAmount(currentValue, ammState.params.vsCoins.currency), {
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 0,
                                notation: 'compact',
                                style: 'currency',
                                currency: 'USD',
                            })}
                    </StatisticsChange>}
            </TableColumn>
        );
    };

    const renderMarketCapColumn = (rollapp: Network): ReactElement => {
        const asset = ammState.assets?.find((asset) => asset.network.chainId === rollapp.chainId && asset.currency.type === 'main');
        const { amount = 0 } = rollapp.totalSupply?.value || {};
        const { amount: previousAmount = 0 } = rollapp.totalSupply?.previousDayValue || {};
        const currentMarketCap = (asset?.price || 0) * amount / Math.pow(10, asset?.currency.decimals || 0);
        const previousDayMarketCap = (asset?.previousDayPrice || 0) * previousAmount / Math.pow(10, asset?.currency.decimals || 0);

        return (
            <TableColumn align='right'>
                {(!asset && (ammState.loading || ammState.paramsLoading || ammState.totalLockedValuesLoading)) ?
                    <Spinner size='small' /> : asset && (
                    <StatisticsChange
                        period='day'
                        currentValue={currentMarketCap}
                        previousValue={previousDayMarketCap}
                    >
                        {formatPrice(currentMarketCap, undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                    </StatisticsChange>
                )}
            </TableColumn>
        );
    };

    const renderNativeTokenLiquidityColumn = (rollapp: Network): ReactElement => {
        const asset = ammState.assets?.find((asset) => asset.network.chainId === rollapp.chainId);
        return (
            <TableColumn align='right'>
                {!asset && ammState.loading ? <Spinner size='small' /> : asset && (
                    <StatisticsChange period='week' currentValue={asset.liquidity} previousValue={asset.liquidity - asset.liquidityDiff}>
                        {formatNumber(
                            asset.liquidity,
                            { maximumFractionDigits: 2, minimumFractionDigits: 0, notation: 'compact', style: 'currency', currency: 'USD' },
                        )}
                    </StatisticsChange>
                )}
            </TableColumn>
        );
    };

    const renderTvlChangeColumn = (rollapp: Network): ReactElement => {
        const data = rollappsAnalyticsState.analyticsMap?.[rollapp.chainId]?.totalSupply;
        const weekData = data && getHistoryValuesInPeriod(data, 'week');

        return (
            <TableColumn align='right'>
                {!data && rollappsAnalyticsState.loadingMap?.[rollapp.chainId] ? <Spinner /> : (
                    weekData && <SimpleLineChart fetchComparableValues={(value) => value.tvl} historyList={weekData} />
                )}
            </TableColumn>
        );
    };

    const onDepositRollappClick = (event: React.MouseEvent, rollapp: Network): void => {
        event.stopPropagation();
        setDepositRollapp(rollapp);
    };

    const renderActionsColumn = (rollapp: Network): ReactElement => {
        return (
            <TableColumn align='right'>
                <Button
                    tooltip={!rollapp.rpc || !rollapp.rest ? 'No deposits possible (RPC endpoints unavailable)' : ''}
                    disabled={!rollapp.rpc || !rollapp.rest}
                    tooltipPlacement='bottom-end'
                    buttonType='secondary'
                    size='small'
                    onClick={(event) => onDepositRollappClick(event, rollapp)}
                >
                    Deposit&nbsp;&nbsp;<ArrowUpRight />
                </Button>
            </TableColumn>
        );
    };

    const renderRollappsHeaderRow = (): ReactElement => {
        return (
            <TableRow header>
                <TableColumn>NAME</TableColumn>
                <TableColumn align='right' info='Total supply market cap'>
                    Market Cap
                </TableColumn>
                <TableColumn align='right' info='Native token amount in liquidity pools'>
                    Token Liquidity
                </TableColumn>
                <TableColumn
                    nowrap
                    align='right'
                    info='Number of completed IBC relayed transfers with the quantity of transfers currently being processed.'
                >
                    IBC transfers (7d)
                </TableColumn>
                <TableColumn align='right' info='Total amount of IBC tokens bridged to the RollApp'>
                    RollApp TVL
                </TableColumn>
                <TableColumn align='right' nowrap>TVL CHANGE (7d)</TableColumn>
                <TableColumn align='right' />
            </TableRow>
        );
    };

    const renderRollappRow = (rollapp: Network): ReactElement => {
        return (
            <TableRow key={rollapp.chainId} onSelect={() => navigate(`/rollapp/${rollapp.chainId}`)}>
                {renderRollappNameColumn(rollapp)}
                {renderMarketCapColumn(rollapp)}
                {renderNativeTokenLiquidityColumn(rollapp)}
                {renderIbcTransfersColumn(rollapp)}
                {renderTvlColumn(rollapp)}
                {renderTvlChangeColumn(rollapp)}
                {renderActionsColumn(rollapp)}
            </TableRow>
        );
    };

    const renderBottomBar = (): ReactElement | undefined => {
        if (loading) {
            return <div className='no-data'><Spinner /></div>;
        }
        if (!sortedRollapps.length) {
            return <div className='no-data'>No RollApps</div>;
        }
    };

    return (
        <div className='page'>
            <RollappsStatistics />

            <RollappsHighlights />

            <div className='rollapps-actionbar'>
                <h4 className='table-header'>Listed RollApps</h4>

                <Button
                    buttonType='secondary'
                    className='list-rollapp-button'
                    onClick={() => window.open(process.env.REACT_APP_ROLLAPP_REGISTRY_URL, '_blank')}
                >
                    <span className='list-rollapp-button-text'>List Your RollApp&nbsp;&nbsp;</span><UploadIcon />
                </Button>

                {addCustomDialogOpen && <AddCustomRollappDialog onRequestClose={() => setAddCustomDialogOpen(false)} />}
            </div>

            <div className='rollapps-table-container'>
                <Table indexColumn firstColumnSticky bottomBar={renderBottomBar()}>
                    {renderRollappsHeaderRow()}
                    {sortedRollapps.map(renderRollappRow)}
                </Table>
            </div>

            {depositRollapp && (
                <IbcTransferDialog
                    title={`Deposit to ${depositRollapp.chainName}`}
                    optionalSourceNetworks={hubNetwork ? [ hubNetwork.chainId ] : []}
                    optionalDestinationNetworks={[ depositRollapp.chainId ]}
                    onRequestClose={() => setDepositRollapp(undefined)}
                />
            )}
        </div>
    );
};

const RollappsPageWithContext = () =>
    <RollappsContextProvider><RollappsPage /></RollappsContextProvider>;

export default RollappsPageWithContext;

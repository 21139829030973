import { createContext, ReactNode, useCallback, useContext, useEffect, useReducer, useState } from 'react';
import { SnackbarMessage, SnackbarMessageKey } from './snackbar-types';
import { snackbarReducer } from './snackbar-state';

interface SnackbarContextValue {
    messages: SnackbarMessage[];
    removeMessage: (messageKey: SnackbarMessageKey) => void;
    getMessage: (messageKey: SnackbarMessageKey) => SnackbarMessage | undefined;
    showMessage: (message: string | Partial<SnackbarMessage>) => void;
    showSuccessMessage: (message: string | Partial<Omit<SnackbarMessage, 'type'>>) => void;
    showErrorMessage: (message: string | Partial<Omit<SnackbarMessage, 'type'>>) => void;
    showWarningMessage: (message: string | Partial<Omit<SnackbarMessage, 'type'>>) => void;
}

export const SnackbarContext = createContext<SnackbarContextValue>({} as SnackbarContextValue);

export const useSnackbar = (): SnackbarContextValue => useContext(SnackbarContext);

export const SnackbarContextProvider = ({ children }: { children: ReactNode }) => {
    const [ snackbarState, snackbarStateDispatch ] = useReducer(snackbarReducer, { messages: [], scheduledMessages: {} });
    const [ , setMessageTimeouts ] = useState<{ [key: SnackbarMessageKey]: NodeJS.Timeout }>({});

    const removeMessage = useCallback((messageKey: SnackbarMessageKey): void =>
        snackbarStateDispatch({ type: 'remove-message', payload: messageKey }), []);

    const getMessage = useCallback((messageKey: SnackbarMessageKey): SnackbarMessage | undefined =>
        snackbarState.messages?.find((message) => message.key === messageKey), [ snackbarState.messages ]);

    const showMessage = useCallback((message: string | Partial<SnackbarMessage>): void =>
        snackbarStateDispatch({ type: 'add-message', payload: typeof message === 'string' ? { content: message } : message }), []);

    const showSuccessMessage = useCallback((message: string | Partial<Omit<SnackbarMessage, 'type'>>) =>
        showMessage({ ...(typeof message === 'string' ? { content: message } : message), type: 'success' }), [ showMessage ]);

    const showErrorMessage = useCallback((message: string | Partial<Omit<SnackbarMessage, 'type'>>) =>
        showMessage({ ...(typeof message === 'string' ? { content: message } : message), type: 'error' }), [ showMessage ]);

    const showWarningMessage = useCallback((message: string | Partial<Omit<SnackbarMessage, 'type'>>) =>
        showMessage({ ...(typeof message === 'string' ? { content: message } : message), type: 'warning' }), [ showMessage ]);

    useEffect(() => {
        Object.keys(snackbarState.scheduledMessages)
            .filter((messageKey) => snackbarState.scheduledMessages[messageKey].toSchedule)
            .forEach((messageKey) => {
                const message = snackbarState.scheduledMessages[messageKey].message;
                setMessageTimeouts((messageTimeouts) => {
                    const previousTimeout = messageTimeouts[message.key];
                    if (previousTimeout) {
                        clearTimeout(previousTimeout);
                    }
                    const timeout = setTimeout(() => removeMessage(message.key), message.duration);
                    return { ...messageTimeouts, [message.key]: timeout };
                });
                snackbarStateDispatch({ type: 'set-message-scheduled', payload: messageKey });
            });
    }, [ snackbarState.scheduledMessages, snackbarState.messages, removeMessage ]);

    return <SnackbarContext.Provider
        value={{
            messages: snackbarState.messages,
            getMessage,
            showMessage,
            showSuccessMessage,
            showErrorMessage,
            showWarningMessage,
            removeMessage,
        }}
    >
        {children}
    </SnackbarContext.Provider>;
};


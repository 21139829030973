import { uniqBy } from 'lodash';
import { AnalyticsData, HistoryList } from '../../../../modules/analytics/analytics-types';
import { AnalyticsChangePeriod } from './statistics-change-types';

export function defaultFetchComparableValues<T>(value: T): number {
    return typeof value === 'number' ? value : Number(value);
}

export function getHistoryValuesInPeriod<T = number>(
    analyticsData: AnalyticsData<T>,
    period: AnalyticsChangePeriod,
    previous?: boolean,
    compareDiffs?: boolean,
): HistoryList<T> {
    let lastTime: number | undefined;
    if (compareDiffs) {
        let lastTimeDate = new Date();
        if (period === 'day') {
            lastTimeDate.setHours(lastTimeDate.getHours() - 1);
            lastTime = lastTimeDate.getTime();
        } else {
            lastTimeDate = excludingMinorUnits(lastTimeDate);
            lastTimeDate.setMilliseconds(lastTimeDate.getMilliseconds() - 1);
        }
        lastTime = lastTimeDate.getTime();
    }
    const firstTime = getDateBefore(period, lastTime, compareDiffs);
    const previousFirstTime = getDateBefore(period, firstTime);
    switch (period) {
        case 'day':
            return !previous && !compareDiffs ? analyticsData.dayHistory :
                getHistoryValuesBetween(
                    compareDiffs ? getUniqHistoryValues(analyticsData.monthHistory, period) : analyticsData.monthHistory,
                    previous ? previousFirstTime : firstTime,
                    previous ? firstTime : lastTime,
                    previous,
                );
        case 'week':
            return getHistoryValuesBetween(
                compareDiffs ? getUniqHistoryValues(analyticsData.monthHistory, period) : analyticsData.monthHistory,
                previous ? previousFirstTime : firstTime,
                previous ? firstTime : lastTime,
                previous,
            );
        case 'month':
            return !previous && !compareDiffs ? analyticsData.monthHistory :
                getHistoryValuesBetween(
                    compareDiffs ? getUniqHistoryValues(analyticsData.totalHistory, period) : analyticsData.totalHistory,
                    previous ? previousFirstTime : firstTime,
                    previous ? firstTime : lastTime,
                    previous,
                );
        case 'year':
            return getHistoryValuesBetween(
                analyticsData.totalHistory,
                previous ? previousFirstTime : firstTime,
                previous ? firstTime : lastTime,
                previous,
            );
        case 'total':
            return analyticsData.totalHistory;
    }
}

export function getCompareValues<T = number>(
    analyticsData: AnalyticsData<T>,
    period: AnalyticsChangePeriod,
    compareDiffs?: boolean,
    fetchComparableValues: (value: T) => number = defaultFetchComparableValues,
): { previousValue: number, currentValue: number } {
    const historyValues = getHistoryValuesInPeriod(analyticsData, period);
    let currentValue = !historyValues?.length ? 0 : fetchComparableValues(historyValues[historyValues.length - 1].value);
    let previousValue = !historyValues?.length ? 0 : fetchComparableValues(historyValues[0].value);

    if (compareDiffs) {
        const previousHistoryValues = getHistoryValuesInPeriod(analyticsData, period, true);
        if (previousHistoryValues?.length) {
            currentValue -= fetchComparableValues(previousHistoryValues[previousHistoryValues.length - 1].value);
            previousValue =
                fetchComparableValues(previousHistoryValues[previousHistoryValues.length - 1].value) -
                fetchComparableValues(previousHistoryValues[0].value);
        } else {
            previousValue = 0;
        }
    }
    return { currentValue, previousValue };
}

function getUniqHistoryValues<T = number>(historyList: HistoryList<T>, period: AnalyticsChangePeriod): HistoryList<T> {
    return uniqBy([ ...historyList ].reverse(), (historyItem) => {
        const date = new Date(historyItem.date);
        return period === 'day' ? `${date.getDate()}-${date.getHours()}` : `${date.getMonth()}-${date.getDate()}`;
    }).reverse();
}

function getHistoryValuesBetween<T = number>(
    historyList: HistoryList<T>,
    firstTime?: number,
    lastTime?: number,
    oneBefore?: boolean,
): HistoryList<T> {
    const list = historyList.filter(({ date }) => (!firstTime || date >= firstTime) && (!lastTime || date < lastTime));
    if (oneBefore) {
        const firstItemIndex = historyList.indexOf(list[0]);
        if (firstItemIndex > 0) {
            list.unshift(historyList[firstItemIndex - 1]);
        }
    }
    return list;
}

const getDateBefore = (period: AnalyticsChangePeriod, from?: number, toExcludingMinorUnits?: boolean): number => {
    let date = from ? new Date(from) : new Date();
    switch (period) {
        case 'day':
            date.setDate(date.getDate() - 1);
            break;
        case 'week':
            date.setDate(date.getDate() - 7);
            break;
        case 'month':
            date.setMonth(date.getMonth() - 1);
            break;
        case 'year':
            date.setFullYear(date.getFullYear() - 1);
            break;
    }
    if (period !== 'day' && toExcludingMinorUnits) {
        date = excludingMinorUnits(date);
    }
    return date.getTime();
};

const excludingMinorUnits = (date: Date): Date => {
    date.setMilliseconds(0);
    date.setSeconds(0);
    date.setMinutes(0);
    date.setHours(0);
    return date;
};

export const getPeriodLabel = (period: AnalyticsChangePeriod): string => {
    switch (period) {
        case 'day':
            return '24h';
        case 'week':
            return '7d';
        case 'month':
            return '30d';
        case 'year':
            return '1y';
        case 'total':
            return 'ALL';
    }
};

import { ReactNode } from 'react';

export const MAX_VISIBLE_MESSAGES = 5;
export const MESSAGE_DEFAULT_DURATION = 10000;

export type SnackbarMessageType = 'info' | 'warning' | 'success' | 'pending' | 'error';

export type SnackbarMessageKey = string | number;

export interface SnackbarMessage {
    title?: string;
    content: ReactNode;
    type: SnackbarMessageType;
    key: SnackbarMessageKey;
    prefix?: ReactNode;
    duration?: number;
    action?: { label: ReactNode; close?: boolean; callback: () => void; },
    closeAction?: boolean;
}

import { Long } from 'cosmjs-types/helpers';
import { MsgTransferEncodeObject } from 'cosmjs/packages/stargate/build/modules/ibc/messages';
import { AccountNetworkState } from '../account/account-network-state';
import { convertToCoin } from '../currency/currency-service';
import { CoinsAmount } from '../currency/currency-types';
import { EIBC_FEE } from './ibc-transfer-context';

interface TransferTxMessageParams {
    sourceData: AccountNetworkState;
    destinationData: AccountNetworkState
    hubNetworkData: AccountNetworkState,
    coins: CoinsAmount,
    balance: CoinsAmount;
    eibc?: boolean
    baseAmountWithoutFee?: bigint
}

const TRANSFER_PORT = 'transfer';

export const getSourceChannel = (sourceData: AccountNetworkState, destinationData: AccountNetworkState) => {
    if (!sourceData.network || !destinationData.network) {
        return '';
    } else if (sourceData.network.type === 'Hub') {
        return destinationData.network.ibc?.hubChannel;
    } else {
        return sourceData.network.ibc?.channel;
    }
};

export const createTransferMessage = ({
    sourceData,
    destinationData,
    hubNetworkData,
    coins,
    balance,
    eibc,
    baseAmountWithoutFee
}: TransferTxMessageParams): MsgTransferEncodeObject => {
    const sourceChannel = getSourceChannel(sourceData, destinationData);
    const isHubMiddleware = sourceData.network?.type !== 'Hub' && destinationData.network?.type !== 'Hub';
    const timeoutTimestamp = Long.fromNumber(new Date().getTime() + (sourceData.network?.ibc?.timeout || 0)).multiply(1_000_000);
    const token = convertToCoin({ ...coins, amount: coins.amount || Math.round(balance.amount / 2) }, balance.ibc?.representation);
    const transferMemo = createTransferMemo(sourceData, destinationData, coins, eibc);
    if (baseAmountWithoutFee !== undefined && baseAmountWithoutFee < BigInt(token.amount)) {
        token.amount = baseAmountWithoutFee.toString();
    }
    return {
        typeUrl: '/ibc.applications.transfer.v1.MsgTransfer',
        value: {
            sourceChannel,
            sender: sourceData.address,
            receiver: isHubMiddleware ? hubNetworkData.address : destinationData.address,
            sourcePort: TRANSFER_PORT,
            token,
            // todo: find better way
            timeoutHeight: { revisionNumber: Long.fromNumber(9999), revisionHeight: Long.ONE },
            timeoutTimestamp,
            memo: transferMemo,
        },
    };
};

export const createTransferMemo = (
    sourceData: AccountNetworkState,
    destinationData: AccountNetworkState,
    coins?: CoinsAmount,
    eibc?: boolean,
): string | undefined => {
    const eibcMemo = !eibc || sourceData.network?.type !== 'RollApp' || !coins ? undefined : {
        eibc: { fee: Math.round(Number(convertToCoin(coins).amount) * EIBC_FEE).toString() },
    };
    const forwardMemo = (sourceData.network?.type === 'Hub' ||
        destinationData.network?.type === 'Hub' ||
        !destinationData.address ||
        !destinationData.network?.ibc?.hubChannel) ? undefined : {
        forward: {
            port: TRANSFER_PORT,
            receiver: destinationData.address,
            channel: destinationData.network.ibc.hubChannel,
        },
    };
    return !eibcMemo && !forwardMemo ? undefined : JSON.stringify({ ...eibcMemo, ...forwardMemo });
};
